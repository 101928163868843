#branding {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 1.8em;
    color: #3e3e3e;
}

.twitter :visited {
    color: #1da1f2;
}

.twitter :hover {
    color: #1da1f2;
}

.linkedin :visited {
    color: #0077b5;
}

.linkedin :hover {
    color: #0077b5;
}