html, body, #root {
    height: 100vh;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
}

h1, h2, h3, h4 {
    color: #3e3e3e;
    font-family: 'Rubik';
}

h1 {
    font-weight: 400;
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -0.00833em;
}

h2 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400 !important;
    font-size: 2.125rem;
    line-height: 1.2;
    letter-spacing: 0.00735em;
}